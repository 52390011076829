import React, { useEffect, useRef } from 'react';

import {
    title,
    description,
    formWrapper,
    sectionBox,
    success,
} from './password-recovery.module.scss';

import { passwordRecoveryForm } from '../../formik/password-recovery.form';
import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';
import { useUser } from '../../hooks/use-user';
import useTranslations from '../../hooks/use-translations';

import FormGenerator from '../molecules/form-generator';

export interface IPasswordRecoveryProps {
    className?: string;
}

export default function PasswordRecovery({ className }: IPasswordRecoveryProps) {
    const t = useTranslations('PasswordRecovery');
    const sectionRef = useRef<HTMLElement>(null);
    const userState = useUser();
    const { formikRef, globalErrorMessage } = useRtkQueryFormError(
        userState.recoverPassword.errors
    );

    useEffect(() => {
        if (!sectionRef.current) return;
        if (userState.recoverPassword.isSuccess) {
            sectionRef.current.scrollIntoView();
        }
    }, [userState.recoverPassword.isSuccess]);

    return (
        <section className={`${className} ${sectionBox}`}>
            {!userState.recoverPassword.isSuccess ? (
                <>
                    <h1 className={title}>{t.title}</h1>
                    <p className={description}>{t.description}</p>
                    <FormGenerator
                        formRef={formikRef}
                        className={formWrapper}
                        formikProps={{
                            onSubmit: (values) => userState.recoverPassword.fetch(values),
                        }}
                        name="PasswordRecovery"
                        fields={passwordRecoveryForm}
                        errorMessage={globalErrorMessage}
                        submitLoading={userState.recoverPassword.isLoading}
                    />
                </>
            ) : (
                <>
                    <h1 className={`${title} ${success}`}>{t.sentTitle}</h1>
                    <p className={description}>{t.sentDescription}</p>
                </>
            )}
        </section>
    );
}
