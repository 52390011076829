// extracted by mini-css-extract-plugin
export var backgroundAnimation = "checkbox-module--backgroundAnimation--b5f2b";
export var checkmark = "checkbox-module--checkmark--ea793";
export var checkmarkIcon = "checkbox-module--checkmark-icon--dfab2";
export var errorBlink = "checkbox-module--error-blink--de976";
export var errorText = "checkbox-module--error-text--b46be";
export var input = "checkbox-module--input--ccc38";
export var required = "checkbox-module--required--ed935";
export var text = "checkbox-module--text--b4a32";
export var wrapper = "checkbox-module--wrapper--01d69";
export var wrapperChecked = "checkbox-module--wrapper-checked--41cbf";
export var wrapperDisabled = "checkbox-module--wrapper-disabled--adc98";
export var wrapperError = "checkbox-module--wrapper-error--84818";