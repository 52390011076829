import React from 'react';
import { ElementContent } from 'react-markdown/lib/ast-to-react';

export interface ICreateImageHolderProps {
    children: React.ReactNode & React.ReactNode[];
    nodeChildren: ElementContent;
    Tag: React.ElementType | string;
    commonClassName?: string;
    className?: string;
    style?: React.CSSProperties;
}

export default function createImageHolder({
    children,
    nodeChildren,
    Tag,
    commonClassName = '',
    className = '',
    style = {},
}: ICreateImageHolderProps) {
    if (nodeChildren.type === 'element' && nodeChildren.tagName === 'img') {
        return (
            <Tag className={`${className} ${commonClassName}`} style={style}>
                {children}
            </Tag>
        );
    }
    return (
        <Tag className={commonClassName} style={style}>
            {children}
        </Tag>
    );
}
