import React from 'react';
import { Field, FieldAttributes, useField } from 'formik';

import {
    checkmark,
    wrapper,
    wrapperError,
    wrapperDisabled,
    input,
    text,
    errorText,
    wrapperChecked,
    required,
    checkmarkIcon,
} from './checkbox.module.scss';

import CheckmarkIcon from '../../../assets/images/svg/checkmark.svg';

import { TRequiredStarPlacement } from '../../models/form-field.model';

import Error from './error';
import Markdown from '../hoc/markdown';

export type ICheckboxProps = {
    name: string;
    isRequired?: boolean;
    requiredPlacement?: TRequiredStarPlacement[];
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
} & FieldAttributes<Record<string | number, any>>;

const Checkbox: React.FC<ICheckboxProps> = ({
    className = '',
    name,
    id,
    disabled,
    context = 'normal',
    isRequired,
    requiredPlacement = ['label'],
    children,
    ...rest
}) => {
    const [, meta] = useField(name);
    const hasError = !!(meta.error && meta.touched);
    const isChecked = meta.value && (meta.value === true || meta.value.includes(rest.value));

    return (
        <label
            className={`${wrapper} ${className} ${hasError ? wrapperError : ''} ${
                disabled ? wrapperDisabled : ''
            } ${isChecked ? wrapperChecked : ''} ${isRequired ? required : ''} checkbox`}
        >
            <Field
                className={`${input} checkbox__input`}
                type={'checkbox'}
                id={id}
                name={name}
                disabled={disabled}
                {...rest}
            />
            <span className={`${checkmark} checkbox__checkmark`}>
                <CheckmarkIcon className={checkmarkIcon} />
            </span>
            {typeof children === 'string' ? (
                <Markdown
                    useDefaultStyles={false}
                    className={`${text} checkbox__text`}
                    // The Markdown component has a function that transforms the 'p' element,
                    // for some unknown reason this transformation makes it necessary
                    // to double-click on the label to select the checkbox.
                    // The following line disables the transformation of the 'p' element.
                    components={{ p: 'p' }}
                >
                    {children}
                </Markdown>
            ) : (
                <span className={`${text} checkbox__text`}>{children}</span>
            )}
            <Error className={errorText} name={name} />
        </label>
    );
};

export default Checkbox;
