import React from 'react';

import IFormField from '../../models/form-field.model';

import Error from '../atoms/error';
import Input from '../atoms/input';
import Button from '../atoms/button';
import Checkbox from '../atoms/checkbox';
import CheckboxGroup from './checkbox-group';
import Select from '../atoms/select';

const FieldGenerator: React.FC<{
    field: IFormField;
    submitLoading?: boolean;
    submitDisabled?: boolean;
    idPrefix?: string;
}> = ({ field, submitLoading, submitDisabled, idPrefix }) => {
    const nameArr = field.name.split('.');
    const className = nameArr[nameArr.length - 1];

    switch (field.type) {
        case 'text':
        case 'email':
        case 'phone':
        case 'password':
        case 'number':
        case 'date':
            return (
                <Input
                    name={field.name}
                    hint={field.hint}
                    id={idPrefix ? `${idPrefix}-${field.name}` : field.name}
                    label={field.label}
                    type={field.type}
                    className={className}
                    placeholder={field.placeholder}
                    disabled={field.disabled}
                    icon={field.icon}
                    iconSide={field.iconSide}
                    isRequired={field.isRequired}
                    requiredPlacement={field.requiredPlacement}
                    min={field.min}
                    max={field.max}
                    pattern={field.pattern}
                />
            );
        case 'textarea':
            return (
                <Input
                    name={field.name}
                    hint={field.hint}
                    id={idPrefix ? `${idPrefix}-${field.name}` : field.name}
                    label={field.label}
                    as={'textarea'}
                    className={className}
                    placeholder={field.placeholder}
                    icon={field.icon}
                    iconSide={field.iconSide}
                    maxLength={field.maxLength}
                    isRequired={field.isRequired}
                    disabled={field.disabled}
                    requiredPlacement={field.requiredPlacement}
                />
            );
        case 'checkbox':
            return (
                <Checkbox
                    name={field.name}
                    hint={field.hint}
                    id={idPrefix ? `${idPrefix}-${field.name}` : field.name}
                    className={className}
                    disabled={field.disabled}
                    isRequired={field.isRequired}
                    requiredPlacement={field.requiredPlacement}
                >
                    {field.label}
                </Checkbox>
            );
        case 'select':
            if (field.options)
                return (
                    <Select
                        name={field.name}
                        className={className}
                        closeMenuOnSelect={field.closeMenuOnSelect}
                        hideSelectedOptions={field.hideSelectedOptions}
                        hint={field.hint}
                        icon={field.icon}
                        isClearable={field.isClearable}
                        isDisabled={field.disabled}
                        isMulti={field.isMulti}
                        isRequired={field.isRequired || field.schema?.spec.presence === 'required'}
                        isSearchable={field.isSearchable}
                        label={field.label}
                        options={field.options}
                        placeholder={field.placeholder}
                        requiredPlacement={field.requiredPlacement}
                        showError={field.showError}
                        imageWithLabel={field.imageWithLabel}
                    />
                );
            return null;
        case 'button':
            return (
                <Button className={className} type={'button'} as={'button'} onClick={field.onClick}>
                    {field.label}
                </Button>
            );
        case 'submit':
            return (
                <Button
                    className={className}
                    type={'submit'}
                    as={'button'}
                    isLoading={submitLoading}
                    isDisabled={submitDisabled}
                >
                    {field.label}
                </Button>
            );
        case 'flag':
            if (field.options)
                return (
                    <CheckboxGroup
                        name={field.name}
                        className={className}
                        options={field.options}
                    />
                );
            return null;
        case 'hidden':
            return <Error name={field.name} />;
        default:
            return null;
    }
};

export default FieldGenerator;
