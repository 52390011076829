import React from 'react';
import { useField } from 'formik';

import { checkboxes, errorText } from './checkbox-group.module.scss';

import { IOption } from '../../models/option.model';

import Checkbox from '../atoms/checkbox';
import Error from '../atoms/error';
import InputLabel from '../atoms/input-label';

interface ICheckboxGroupProps {
    className?: string;
    name: string;
    label?: string;
    hint?: string;
    options: readonly IOption[];
    isDisabled?: boolean;
    isRequired?: boolean;
}

const CheckboxGroup: React.FC<ICheckboxGroupProps> = ({
    className = '',
    options,
    name,
    label,
    hint,
    isRequired = false,
    isDisabled = false,
}) => {
    const [, meta] = useField(name);
    const hasError = !!(meta.error && meta.touched);

    return (
        <div className={`${className} checkbox-group`}>
            {label && (
                <InputLabel
                    size="large"
                    hint={hint}
                    isError={hasError}
                    isRequired={isRequired}
                    isDisabled={isDisabled}
                >
                    {label}
                </InputLabel>
            )}
            <div
                className={`${checkboxes} checkbox-group__options`}
                role="group"
                aria-labelledby={`checkbox-group-${name}`}
            >
                {options.map((option) => (
                    <Checkbox
                        key={`${name}-${option.value}`}
                        name={name}
                        value={option.value}
                        disabled={!option.enabled || isDisabled}
                    >
                        {option.label}
                    </Checkbox>
                ))}
            </div>
            <Error className={errorText} name={name} />
        </div>
    );
};

export default CheckboxGroup;
