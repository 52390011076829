import React, { useEffect, useRef } from 'react';

declare global {
    interface Window {
        instgrm: any;
        FB: any;
    }
}

const MarkdownScript = (props: Record<string, any>) => {
    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!container.current) return;

        const script = document.createElement('script');
        Object.entries(props).forEach(([key, value]) => script.setAttribute(key, value));

        keysToDelete.forEach((key) => {
            if (window[key]) {
                delete window[key];
            }
        });

        container.current.append(script);
    }, [props]);

    return <div ref={container} />;
};

const keysToDelete = ['instgrm', 'FB'] as const;

export default MarkdownScript;
