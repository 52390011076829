import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';

import { defaultStyles, imageHolder } from './markdown.module.scss';

import MarkdownLink from '../atoms/markdown-link';
import MarkdownScript from '../atoms/markdown-script';
import createImageHolder from '../../utils/create-image-holder';

interface IMarkdownProps extends ReactMarkdownOptions {
    children: string;
    components?: ReactMarkdownOptions['components'];
    className?: string;
    useDefaultStyles?: boolean;
}

const Markdown: React.FC<IMarkdownProps> = ({
    children,
    className = '',
    components,
    useDefaultStyles = true,
    ...rest
}) => {
    return (
        <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            components={{
                h1: 'h2',
                a: ({ href, children, target, className, style }) => (
                    <MarkdownLink href={href} target={target} className={className} style={style}>
                        {children}
                    </MarkdownLink>
                ),
                script: ({ node, ...props }) => <MarkdownScript {...props} />,
                figure: ({ children, node, className, style }) => {
                    if (
                        node.properties &&
                        Array.isArray(node.properties.className) &&
                        node.properties.className.includes('table')
                    ) {
                        return (
                            <div className={className} style={style}>
                                {children}
                            </div>
                        );
                    }
                    return createImageHolder({
                        children,
                        nodeChildren: node.children[0],
                        Tag: node.tagName,
                        commonClassName: className,
                        className: imageHolder,
                        style: style,
                    });
                },
                ...components,
            }}
            className={`${className} ${useDefaultStyles ? defaultStyles : ''} ck-content`}
            {...rest}
        >
            {children}
        </ReactMarkdown>
    );
};

export default Markdown;
