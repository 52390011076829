import Url from 'url';
import { useLocation } from '@reach/router';

export function useIsLinkInternal(url?: string) {
    const { hostname } = useLocation();

    if (!url) return;

    const parsedUrl = Url.parse(url);
    return parsedUrl.hostname === null || parsedUrl.hostname === hostname;
}
