import React from 'react';
import * as Yup from 'yup';
import { AnySchema } from 'yup';
import { Form, Formik, FormikValues } from 'formik';

import { container, form, error } from './form-generator.module.scss';

import IFormField, {
    TFormFieldsFactory,
    TFormFieldsFactoryProps,
} from '../../models/form-field.model';
import { IError } from '../../utils/get-form-errors';
import { TLocale } from '../../locale';
import useTranslations from '../../hooks/use-translations';

import Field from './field-generator';
import HandleFormikChange, { IHandleFormikChange } from '../hoc/handle-formik-change';

export type FormikProps<Values extends FormikValues> = Parameters<typeof Formik<Values>>[0];

interface IFormAppProps<
    Values extends FormikValues,
    FormName extends keyof TLocale,
    // eslint-disable-next-line @typescript-eslint/ban-types
    ExtraProps extends Record<string, unknown> = {}
> {
    className?: string;
    formClassName?: string;
    formikProps: Omit<FormikProps<Values>, 'validationSchema' | 'initialValues' | 'innerRef'> &
        Partial<Pick<FormikProps<Values>, 'initialValues'>>;
    name: FormName;
    fields: TFormFieldsFactory<FormName, ExtraProps>;
    extraFieldsProps?: ExtraProps;
    formRef?: FormikProps<Values>['innerRef'];
    errorMessage?: string;
    otherErrors?: IError[];
    submitLoading?: boolean;
    submitOnlyOnChange?: boolean;
    children?: React.ReactNode;
    handleFormikChange?: IHandleFormikChange['onChange'];
    bottomChildren?: React.ReactNode;
}

function FormGenerator<
    Values extends FormikValues,
    FormName extends keyof TLocale,
    ExtraProps extends Record<string, unknown>
>(props: IFormAppProps<Values, FormName, ExtraProps>) {
    const {
        formikProps,
        fields,
        extraFieldsProps,
        className = '',
        formClassName = '',
        name,
        formRef,
        errorMessage,
        otherErrors,
        submitLoading = false,
        submitOnlyOnChange = false,
        children,
        handleFormikChange,
        bottomChildren,
    } = props;
    const t = useTranslations(name);

    const translatedFields = fields({ ...extraFieldsProps, t } as TFormFieldsFactoryProps<
        FormName,
        ExtraProps
    >);

    const initialValues = {
        ...getInitialValues(translatedFields),
        ...formikProps.initialValues,
    } as Values;
    const validationSchema = getValidationSchema(translatedFields);

    const otherErrorsMessage = otherErrors
        ? otherErrors.map((error) => error.content).join(',')
        : null;

    return (
        <div className={`${container} ${className}`}>
            <Formik
                {...formikProps}
                initialValues={initialValues}
                validationSchema={validationSchema}
                innerRef={formRef}
            >
                {({ dirty }) => (
                    <Form className={`${formClassName} ${form}`}>
                        <>
                            <HandleFormikChange onChange={handleFormikChange} />
                            {children}
                            {translatedFields.map((field, index) => {
                                if (field.type === 'submit') {
                                    return (
                                        <Field
                                            field={field}
                                            key={index}
                                            submitLoading={submitLoading}
                                            submitDisabled={submitOnlyOnChange ? !dirty : false}
                                        />
                                    );
                                }
                                return <Field field={field} key={index} idPrefix={name} />;
                            })}
                            {errorMessage && <FormGenerator.Error errorMessage={errorMessage} />}
                            {otherErrorsMessage && (
                                <FormGenerator.Error errorMessage={otherErrorsMessage} />
                            )}
                            {bottomChildren}
                        </>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

FormGenerator.Error = ({ errorMessage }: { errorMessage: string }) => (
    <output className={`${error} output`}>{errorMessage}</output>
);

function getInitialValues<Values extends FormikValues>(fields: readonly IFormField[]): Values {
    const initialValues: { [key: string]: any } = {};
    fields.forEach((field) => {
        if (field.initialValue !== undefined) {
            initialValues[field.name] = field.initialValue;
        }
    });
    return initialValues as Values;
}

function getValidationSchema(fields: readonly IFormField[]) {
    const validationSchema: { [key: string]: AnySchema } = {};
    fields.forEach((field) => {
        if (field.schema !== undefined) {
            validationSchema[field.name] = field.schema;
        }
    });
    return Yup.object().shape(validationSchema);
}

export default FormGenerator;
